import React from "react";
import { Container} from "react-bootstrap";

const Footer = () => {
    return (
        <Container fluid className="d-flex justify-content-center align-items-center bg-dark text-light fixed-bottom">
            this is the footer
        </Container>
    )
}
export default Footer