import axios from "axios";
const getState = ({ getStore, getActions, setStore }) => {
    return {
        store: {
            jarResponse: false,
            progress: 0,
        },
        actions: {
            submitDataToBackend: async (formData) => {
                axios
                    .post("/saft", formData, {
                        onUploadProgress: (progressEvent) => {
                            if (progressEvent.bytes) {
                                setStore({
                                    progress: Math.round(
                                        (progressEvent.loaded /
                                            progressEvent.total) *
                                            100
                                    ),
                                });
                            }
                        },
                    })
                    .then(function (response) {
                        setStore({
                            jarResponse: response,
                        });
                        console.log("response on flux", response);
                    })
                    .catch(function (error) {
                        setStore({ jarResponse: error.response });
                        console.log("error on flux", error.response);
                    });
            },
            downloadFile: () => {
                const fileName = sessionStorage.getItem("fileName"); // retrieve file name from session storage

                axios({
                    url: `/download/${fileName}`,
                    method: "GET",
                    responseType: "blob", // indicates the response type should be a blob
                }).then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;

                    // extract file extension from file name
                    const fileExt = fileName.slice(fileName.lastIndexOf("."));

                    // insert string before file extension
                    const newFileName = fileName.replace(
                        fileExt,
                        ".multidocumento" + fileExt
                    );

                    link.setAttribute("download", newFileName);
                    document.body.appendChild(link);
                    link.click();
                });
            },
        },
    };
};
export default getState;
