import React, { useContext, useState } from "react";
import { Context } from "../../Store/appContext";
import { useFormik } from "formik";
import validationSchema from "./validationSchema";
import monthOptions from "./monthOptions";
import yearOptions from "./yearOptions";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSquareCheck,
    faSquareArrowUpRight,
    faEyeSlash,
    faEye,
} from "@fortawesome/free-solid-svg-icons";

import ProgressBar from "react-bootstrap/ProgressBar";
import { InputGroup } from "react-bootstrap";

const date = new Date();

const FormComponent = () => {
    const { store, actions } = useContext(Context);

    const [showPassword, setShowPassword] = useState(false);

    const [progressBar, setProgressBar] = useState(false);

    const formik = useFormik({
        initialValues: {
            nif: "",
            password: "",
            file: "",
            month: String(date.getMonth() + 1).padStart(2, "0"),
            year: date.getFullYear(),
            option: "validar",
            test: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            setProgressBar(true);

            sessionStorage.setItem("fileName", values.file.name.replace(/ /g, "_").toLowerCase());
            console.log(values.file)

            formData.append("nif", values.nif);
            formData.append("password", values.password);
            formData.append("year", values.year);
            formData.append("month", values.month);
            formData.append("option", values.option);
            formData.append("test", values.test);
            formData.append("file", values.file);
            formData.append("folder", date.getHours()+ "" + date.getMinutes()+ "" + date.getSeconds() + "" +  date.getDate()+ "" + (date.getMonth()+1) + "" + date.getFullYear())
            actions.submitDataToBackend(formData);
        },
    });

    const handleFileChange = (event) => {
        formik.setFieldValue("file", event.currentTarget.files[0]);
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Row className="g-3">
                    <Col xs={12} lg={6}>
                        <Form.Group
                            controlId="nif"
                            className="mb-3"
                            data-testid="nif"
                        >
                            <Form.Label>Nif</Form.Label>
                            <Form.Control
                                size="lg"
                                type="number"
                                name="nif"
                                placeholder="insira o seu nif"
                                value={formik.values.nif}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={
                                    formik.touched.nif && formik.errors.nif
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.nif}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Form.Group
                            controlId="password"
                            className="mb-3"
                            data-testid="password"
                        >
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    size="lg"
                                    type={!showPassword ? "password" : "text"}
                                    name="password"
                                    placeholder="insira a sua password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.password &&
                                        formik.errors.password
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.password}
                                </Form.Control.Feedback>
                                <Button
                                    variant="outline-secondary"
                                    onClick={handleShowPassword}
                                >
                                    {!showPassword ? (
                                        <FontAwesomeIcon icon={faEye} />
                                    ) : (
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                    )}
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col xs={12} lg={6}>
                        <Row>
                            <Form.Group
                                controlId="file"
                                className="mb-3"
                                data-testid="file"
                            >
                                <Form.Label>Ficheiro SAFT</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="file"
                                    name="file"
                                    accept="text/xml"
                                    placeholder="Adicione o seu ficheiro"
                                    onChange={handleFileChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.file &&
                                        formik.errors.file
                                    }
                                />
                                {progressBar ? (
                                    <ProgressBar
                                        animated
                                        now={store.progress}
                                    />
                                ) : (
                                    ""
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.file}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Label>Escolha a sua opção</Form.Label>
                            <Form.Group
                                controlId="option"
                                className="mb-3"
                                data-testid="option"
                            >
                                <Form.Check
                                    inline
                                    name="option"
                                    type="radio"
                                    id="validate"
                                    label="validar"
                                    value="validar"
                                    checked={formik.values.option === "validar"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <Form.Check
                                    inline
                                    name="option"
                                    type="radio"
                                    id="send"
                                    label="enviar"
                                    value="enviar"
                                    checked={formik.values.option === "enviar"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Form.Group>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.option}
                            </Form.Control.Feedback>
                        </Row>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group
                                    controlId="month"
                                    className="mb-3"
                                    data-testid="month"
                                >
                                    <Form.Label>Mês</Form.Label>
                                    <Form.Select
                                        size="lg"
                                        type="select"
                                        placeholder="Escolha o Mes"
                                        value={formik.values.month}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                "month",
                                                e.target.value
                                            )
                                        }
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                            formik.touched.month &&
                                            formik.errors.month
                                        }
                                    >
                                        {monthOptions.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.month}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group
                                    controlId="year"
                                    className="mb-3"
                                    data-testid="year"
                                >
                                    <Form.Label>Ano</Form.Label>
                                    <Form.Select
                                        size="lg"
                                        type="select"
                                        placeholder="Escolha o Ano"
                                        value={formik.values.year}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                "year",
                                                e.target.value
                                            )
                                        }
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                            formik.touched.month &&
                                            formik.errors.month
                                        }
                                    >
                                        {yearOptions.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.month}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label>Este é um envio de teste?</Form.Label>
                            <Form.Group
                                className="mb-3"
                                controlId="formSwitch"
                                data-testid="formSwitch"
                            >
                                <Form.Check
                                    type="switch"
                                    id="test-switch"
                                    label="Sim"
                                    checked={formik.values.test}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "test",
                                            e.target.checked ? "-t" : ""
                                        )
                                    }
                                    onBlur={formik.handleBlur}
                                />
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col xs={12} lg={2} className="d-grid mb-3">
                        {formik.values.test === "" &&
                        formik.values.option === "validar" ? (
                            <Button
                                type="submit"
                                variant="primary"
                                className="justify-content-center"
                            >
                                <FontAwesomeIcon
                                    icon={faSquareCheck}
                                    size="xl"
                                />
                                <h5>Validar</h5>
                            </Button>
                        ) : (
                            ""
                        )}
                        {formik.values.test === "-t" &&
                        formik.values.option === "validar" ? (
                            <Button
                                type="submit"
                                variant="outline-primary"
                                className="justify-content-center"
                            >
                                <FontAwesomeIcon
                                    icon={faSquareCheck}
                                    size="xl"
                                />
                                <h5>Teste de Validação</h5>
                            </Button>
                        ) : (
                            ""
                        )}
                        {formik.values.test === "" &&
                        formik.values.option === "enviar" ? (
                            <Button
                                type="submit"
                                variant="danger"
                                className="justify-content-center"
                            >
                                <FontAwesomeIcon
                                    icon={faSquareArrowUpRight}
                                    size="xl"
                                />
                                <h5>Enviar</h5>
                            </Button>
                        ) : (
                            ""
                        )}
                        {formik.values.test === "-t" &&
                        formik.values.option === "enviar" ? (
                            <Button
                                type="submit"
                                variant="outline-danger"
                                className="justify-content-center"
                            >
                                <FontAwesomeIcon
                                    icon={faSquareArrowUpRight}
                                    size="xl"
                                />
                                <h5>Teste de Envio</h5>
                            </Button>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default FormComponent;
