import * as Yup from 'yup'
const MAX_FILE_SIZE = 1024 * 1024 * 100; // 100MB

const validationSchema = Yup.object().shape({
    nif: Yup.string()
        .required("o campo é obrigatório")
        .min(9, "o NIF é constituido por 9 digitos"),
    password: Yup.string().required("o campo é obrigatório"),
    file: Yup.mixed()
        .required("Escolha um ficheiro")
        .test("fileType", "O arquivo deve ser um arquivo XML", (value) => {
            return value ? value.type === "text/xml" : false;
        })
        .test(
            "fileSize",
            `O arquivo é muito grande. O tamanho máximo permitido é de 100 MB`,
            (value) => {
                return value ? value.size <= MAX_FILE_SIZE : false;
            }
        ),
});
export default validationSchema