import React, { useContext } from "react";
import { Context } from "../../../Store/appContext";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice, faArrowTrendUp, faArrowTrendDown } from '@fortawesome/free-solid-svg-icons'

const ResponseComponent = () => {
    const { store,actions } = useContext(Context);

    let totalFaturas = store.jarResponse.data?.stdout.match(
        /<totalFaturas>([^<]*)<\/totalFaturas>/
    )[1]

    let totalCreditos = store.jarResponse.data?.stdout.match(
        /<totalCreditos>([^<]*)<\/totalCreditos>/
    )[1]

    let totalDebitos = store.jarResponse.data?.stdout.match(
        /<totalDebitos>([^<]*)<\/totalDebitos>/
    )[1]

    return (
        <>
            <Col xs={12} lg={4} className="justify-content-center flex my-3">
                <Card className="text-center border-radius border-primary" >
                    <Card.Header className="text-primary">
                        <FontAwesomeIcon icon={faFileInvoice} />
                        <h5>Total de Faturas</h5>
                    </Card.Header>
                    <Card.Body style={{ height: '15vh' }} className="d-flex align-content-center justify-content-center bg-primary bg-opacity-75 text-white">
                        <Card.Title className="fs-1 align-self-center">
                            {totalFaturas}
                        </Card.Title>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={4} className="justify-content-center flex my-3">
                <Card className="text-center border-radius border-success" >
                    <Card.Header className="text-success">
                        <FontAwesomeIcon icon={faArrowTrendUp} />
                        <h5>Total de Créditos</h5>
                    </Card.Header>
                    <Card.Body style={{ height: '15vh' }} className="d-flex align-content-center justify-content-center bg-success bg-opacity-75 text-white" >
                        <Card.Title className="fs-1 align-self-center">
                            {totalCreditos}
                        </Card.Title>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={4} className="justify-content-center flex my-3">
                <Card className="text-center border-radius border-danger" >
                    <Card.Header className="text-danger">
                        <FontAwesomeIcon icon={faArrowTrendDown} />
                        <h5>Total de Débitos</h5>
                    </Card.Header>
                    <Card.Body style={{ height: '15vh' }} className="d-flex align-content-center justify-content-center bg-danger bg-opacity-75 text-white" >
                        <Card.Title className="fs-1 align-self-center">
                            {totalDebitos}
                        </Card.Title>
                    </Card.Body>
                </Card>
            </Col>
            <Row>
                <Col>
                    <Button onClick={actions.downloadFile}>download</Button>
                </Col>
            </Row>
        </>
    );
};
export default ResponseComponent;
