import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from 'react-bootstrap/Navbar';
import Logo from "./logo.png";

const Header = () => {
    return (
        <>
            <Navbar bg="dark" variant="dark" fixed="top">
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            alt="aloha logo"
                            src={Logo}
                            width="100"
                            className="d-inline-block align-top"
                        />{' '}
                        Saft Upload
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </>
    )
}
export default Header