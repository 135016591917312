import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import injectContext from "./Store/appContext";

import Header from "./Components/Header/Header";
import CustomerFeedback from "./Components/CustomerFeedback/CustomerFeedback";
import FormComponent from "./Components/Form/Form";
import Footer from "./Components/Footer/Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";



function App() {
    return (
        <div className="App align-items-center" data-testid="App">
            <Header />
            <Container className="min-vh-100 d-flex justify-content-center align-items-center">
                <Col xs={12} lg={8} >
                    <Row className="py-3">
                        <CustomerFeedback />
                    </Row>
                    <Row className="py-3">
                        <FormComponent />
                    </Row>
                </Col>
            </Container>
            <Footer />
        </div>
    );
}

export default injectContext(App);
