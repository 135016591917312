import React, { useContext } from "react";
import { Context } from "../../Store/appContext";
import ResponseComponent from "./Components/ResponseComponent";
import ErrorComponent from "./Components/ErrorComponent";

const CustomerFeedback = () => {
    const { store } = useContext(Context);

    return (
        <>
            {store.jarResponse.status === 200 ? (
                <ResponseComponent />
            ) : store.jarResponse.status === 400 ||
              store.jarResponse.status === 500 ? (
                <ErrorComponent />
            ) : (
                ""
            )}
        </>
    );
};
export default CustomerFeedback;
