import React, { useContext, useState } from "react";
import { Context } from "../../../Store/appContext";


import Alert from "react-bootstrap/Alert";

const ErrorComponent = () => {
    const { store } = useContext(Context);

    const [show, setShow] = useState(true);

    return (
        <Alert
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
        >
            <Alert.Heading>Ocorreu o seguinte erro</Alert.Heading>
            {store.jarResponse.status === 400 ? (
                <>
                    <p>
                        {store.jarResponse.data?.stdout.substring(
                            store.jarResponse.data.stdout.indexOf(
                                "Parametro"
                            ) + 10,
                            store.jarResponse.data.stdout.indexOf(
                                "Parametro"
                            ) + 15
                        )}
                    </p>
                    <p>
                        {store.jarResponse.data?.stdout.substring(
                            store.jarResponse.data.stdout.indexOf(
                                "Parametro"
                            ) + 16,
                            store.jarResponse.data.stdout.indexOf(
                                "Parametro"
                            ) + 141
                        )}
                    </p>
                </>
            ) : (
                <>
                    <p>
                        {
                            store.jarResponse.data?.stdout.match(
                                /<response code="([^<]*)">/
                            )[1]
                        }
                    </p>
                    <p>
                        {
                            store.jarResponse.data?.stdout.match(
                                /<error>([^<]*)<\/error>/
                            )[1]
                        }
                    </p>
                </>
            )}
        </Alert>

    );
};
export default ErrorComponent;
